import React from "react"
import Layout from "../components/layout"
import blackthornStyles from "./blackthorn.module.css"
import Swiper, {Navigation,Pagination} from 'swiper'

import 'swiper/swiper-bundle.css'
//import {Slide} from 'react-slideshow-image'
//import Content_surround from "../components/content_surround"
//import {Link} from "gatsby"


/*Swiper.use([Navigation,Pagination]);
const swiper = new Swiper ('.swiper-container',{
  direction:'horizontal',
  loop:false,
  pagination:{
    el:'.swiper-pagination'
  },
  navigation:{
    nextEl:'.swiper-button-next',
    prevEl:'.swiper-button-prev'
  }
});*/

export default function Blackthorn() {
  return (
    <Layout image="https://admhotels.com/images/Hotel-concept-rendering.jpg" page_title="Blackthorn Development">
        <h1 className="text-center">Blackthorn Development</h1>
        <p>Slide Presentation:</p>
    {/*  <div className={blackthornStyles.presentation}>
          <div className={"swiper-container " + blackthornStyles.swiper_container}>
            <div className="swiper-wrapper">
              <div className="swiper-slide">
                <img src="https://admhotels.com/images/portfolio/S0.JPG" alt=""/>
              </div>
              <div className="swiper-slide">
                <img src="https://admhotels.com/images/portfolio/S2_20201009a.JPG" alt=""/>
              </div>
              <div className="swiper-slide">
                <img src="https://admhotels.com/images/portfolio/S2.JPG" alt=""/>
              </div>
              <div className="swiper-slide">
                <img src="https://admhotels.com/images/portfolio/S3.JPG" alt=""/>
              </div>
              <div className="swiper-slide">
                <img src="https://admhotels.com/images/portfolio/S5_20201009a.JPG" alt=""/>
              </div>
              <div className="swiper-slide">
                <img src="https://admhotels.com/images/portfolio/S5.JPG" alt=""/>
              </div>
              <div className="swiper-slide">
                <img src="https://admhotels.com/images/portfolio/S6.JPG" alt=""/>
              </div>
              <div className="swiper-slide">
                <img src="https://admhotels.com/images/portfolio/S6-2.JPG" alt=""/>
              </div>
              <div className="swiper-slide">
                <img src="https://admhotels.com/images/portfolio/S11_20201009a.JPG" alt=""/>
              </div>
              <div className="swiper-slide">
                <img src="https://admhotels.com/images/portfolio/S12_20201009a.JPG" alt=""/>
              </div>
              <div className="swiper-slide">
                <img src="https://admhotels.com/images/portfolio/S9.JPG" alt=""/>
              </div>
            </div>
            <div className="swiper-button-prev"></div>
            <div className="swiper-button-next"></div>
          </div>
        </div>*/}

        <div className={blackthornStyles.presentation}>
              <div className="swiper-slide">
                <img src="https://admhotels.com/images/portfolio/S0.JPG?10" alt=""/>
              </div>
              <div className="swiper-slide">
                <img src="https://admhotels.com/images/portfolio/S1.JPG?20" alt=""/>
              </div>
              <div className="swiper-slide">
                <img src="https://admhotels.com/images/portfolio/S2.JPG?20" alt=""/>
              </div>
              <div className="swiper-slide">
                <img src="https://admhotels.com/images/portfolio/S3.JPG?10" alt=""/>
              </div>
              <div className="swiper-slide">
                <img src="https://admhotels.com/images/portfolio/S4.JPG?10" alt=""/>
              </div>
              <div className="swiper-slide">
                <img src="https://admhotels.com/images/portfolio/S5.JPG?10" alt=""/>
              </div>
              <div className="swiper-slide">
                <img src="https://admhotels.com/images/portfolio/S6.JPG?10" alt=""/>
              </div>
              <div className="swiper-slide">
                <img src="https://admhotels.com/images/portfolio/S6-2.JPG?10" alt=""/>
              </div>
              <div className="swiper-slide">
                <img src="https://admhotels.com/images/portfolio/Slide11.JPG?20" alt=""/>
              </div>
              <div className="swiper-slide">
                <img src="https://admhotels.com/images/portfolio/Exhibit_8.jpg?11" alt=""/>
              </div>
              <div className="swiper-slide">
                <img src="https://admhotels.com/images/portfolio/S9.JPG?10" alt=""/>
              </div>
        </div>

{/*
  
        <div className={blackthornStyles.blackthorn_section}>

          <div className={blackthornStyles.title}>
            <h2>Sheraton by Mariott</h2>
          </div>

          <div className={blackthornStyles.inner}>
            <img src="https://admhotels.com/images/Hotel-concept-rendering.jpg" alt=""/>
            <p className="text-center">Hotel, Resort, & Conference Center at Blackthorn</p>
            <p className="text-center">South Bend, IN</p>

            <h2 className="text-center">Ammenities & Services</h2>
            <p>350 Guest Room 5 star Full-Service Luxury Hotel</p>
            <p>- Including 77 Executive Suites</p>
            <p>- 2 presidential suites</p>
            <p>- 100 "Class A" Luxury Residential condominium units catering especially to Notre Dame Alumni</p>
            <p>50,000 sqft. Conference Center / Banquet Hall</p>
            <p>1,200 Space Parking garage</p>
            <p>Indoor/Outdoor Pool</p>
            <p>Destination Restaurant</p>
            <p>-Upscale Full-Service Restaurant</p>
            <p>-Breakfast Nook</p>
            <p>-Sports Bar</p>
            <p>Limousine Service</p>
            <p>PROJECT BUDGET: $500 Million</p>

            <h2 className="text-center">Location</h2>
            <p className="text-center">Blackthorn Corporate Park at South Bend, IN</p>
            <img src="https://admhotels.com/images/Hotel-location.png" alt=""/>
            <h2 className="text-center">The Sheraton Hotel & Resort</h2>
            <p>State-of-the-art Golf Course Designed by Jack Nicklaus</p>
            <p>Green, sustainable and environmentally-friendly</p>
            <p>A premier destination facility</p>
            <p>Connected to the regional community and provide year-round activities</p>
            <p>A leading player in desitnation events</p>
            <p>Accessible to the private portion of the Airport</p>
            <p>World-class Golf and Tennis Destination</p>
            <p>Directly connected to Blackthorn Golf Course and will provide access to other local golf courses.</p>
            <p>Biking and Hiking Trails</p>
            <p>A major economic catalyst</p>
          </div>

        </div>

        <div className={blackthornStyles.blackthorn_section}>

          <div className={blackthornStyles.title}>
            <h2>South Bend Partnership</h2>
          </div>

          <div className={blackthornStyles.inner}>

            <h2>Estimated Economic Impacts</h2>
            <p>-Construction Jobs ~275 (on-site, plus support/vendors)</p>
            <p>-Full-Time/Part-Time Employees ~500+</p>
            <p>-Spin-off Jobs ~550</p>
            <p>-Total Economic Development Value: TBD</p>
            <p>-Redevelopment Community Project - Benefits realized will remain in local economy</p>

          </div>
        </div>

        <div className={blackthornStyles.blackthorn_section}>

          <div className={blackthornStyles.title}>
            <h2>Conceptual Design</h2>
          </div>

          <div className={blackthornStyles.inner}>
            <h2>Conceptual Site Plan</h2>
            <img src="https://admhotels.com/images/hotel-concept-siteplan1.jpg" alt=""/>

            <h2>Conceptual Floor Plan</h2>
            <img src="https://admhotels.com/images/hotel-concept-siteplan2.jpg" alt=""/>

          </div>
        </div>

        <div className={blackthornStyles.blackthorn_section}>

          <div className={blackthornStyles.title}>
            <h2>Development Team</h2>
          </div>

          <div className={blackthornStyles.inner}>
            <ul>
              <li>Real Estate & Holding Company - Blackthorn Hotel Development, LLC</li>
              <li>Owner & Principal - Jerry L. Dominiack</li>
              <li>Developer - ADM Hotel & Resorts Group, L.L.C</li>
              <li>Developer Representatives - Nausher Ahmad Sial</li>
              <li>Legal Counsel to project - Richard A. Nussbaum II</li>
              <li>Real Estate Broker of Development - C.E Capital, LLC, George Cressy</li>
              <li>Architect - CSO Architects, Inc., Alan R. Tucker, AIA, LEED AP</li>
              <li>Construction Manager - Walsh Construction</li>
            </ul>
            
          </div>
        </div>


        <div className={blackthornStyles.blackthorn_section}>

          <div className={blackthornStyles.title}>
            <h2>Development Schedule</h2>
          </div>

          <div className={blackthornStyles.inner}>

          <img src="https://admhotels.com/images/portfolio/S7.JPG" alt=""/>
            
          </div>
        </div>
*/}

        

        


 
    </Layout>



  ) 
}
